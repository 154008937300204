import React, {useEffect, useContext, useState} from 'react';
import {Button, Form, Input, Table, Row, Col, Popconfirm} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {AppContext} from '../../Provider';
import './styles.scss'
import { addData, deleteData, getData } from '../../controller/control';

export default function Users(){
    const [state, setState] = useContext(AppContext)
    const [users, setUsers] = useState([])
    const [form] = Form.useForm();

    const navigate = useNavigate()

    const getUsers = async() => {
        const arrayUsers = await getData(`${process.env.REACT_APP_API_URL}/api/user/`, state?.token)
        if(arrayUsers && arrayUsers.length > 0){
            setUsers(arrayUsers)
        }
    }

    useEffect(() => {
        getUsers()
    }, [0])

    const deleteUser = async(value) => {
        const deleteArrayUser = await deleteData(`${process.env.REACT_APP_API_URL}/api/user/${value._id}`, state?.token)
        if(deleteArrayUser === 200){
            getUsers()
        }
    }

    const onFinish = async(values) => {
        const addUser = await addData(values, `${process.env.REACT_APP_API_URL}/api/user/`, state?.token)
        if(addUser && addUser._id){
            getUsers()
            form.resetFields()
        }else{
            alert('Error General')
        }
    }

    const columns = [
        {
          title: "Usuario",
          dataIndex: "user",
          key: "user"
        },
        {
          title: "Contraseña",
          dataIndex: "pass",
          key: "pass"
        },
        {
          title: "Tabla",
          dataIndex: "table",
          key: "table"
        },
        {
            title: "Eliminar",
            render: (a) =>  <Popconfirm 
                                title="Seguro quiere borrar este usuario?" 
                                onConfirm={() => deleteUser(a)}
                                okText="Si" 
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>,
            key: "delete"
        }       
    ]

    return(
        <div className='users'>
            <div className='div-page'>
                <div><h1>Usuarios</h1></div>
                <Row>
                    <Col className='add-user'>
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item
                                label='Usuario'
                                name="user"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label='Contraseña'
                                name="pass"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label='Tabla'
                                name="table"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit">
                                    Agregar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Table dataSource={users} columns={columns}/>
            </div>
        </div>
    )
}