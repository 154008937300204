import React, {useEffect, useContext, useState} from 'react';
import {Button, Form, Input, Table, Row, Col, Popconfirm} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {AppContext} from '../../Provider';
import './styles.scss'
import { addData, deleteData, getData } from '../../controller/control';

export default function Codes(){
    const [state, setState] = useContext(AppContext)
    const [codes, setCodes] = useState([])
    const [form] = Form.useForm();

    const navigate = useNavigate()

    const getCodes = async() => {
        const arrayCodes = await getData(`${process.env.REACT_APP_API_URL}/api/code/`, state?.token)
        if((arrayCodes && arrayCodes.length >= 0)){
            setCodes(arrayCodes)
        }
    }

    useEffect(() => {
        getCodes()
    }, [0])

    const deleteCode = async(value) => {
        const deleteArrayCode = await deleteData(`${process.env.REACT_APP_API_URL}/api/code/${value._id}`, state?.token)
        if(deleteArrayCode === 200){
            getCodes()
        }
    }

    const onFinish = async(values) => {
        const addCode = await addData(values, `${process.env.REACT_APP_API_URL}/api/code/`, state?.token)
        if(addCode && addCode._id){
            getCodes()
            form.resetFields()
        }else{
            alert('Error General')
        }
    }

    const columns = [
        {
          title: "Codigo",
          dataIndex: "code",
          key: "code"
        },
        {
            title: "Eliminar",
            render: (a) =>  <Popconfirm 
                                title="Seguro quiere borrar este Código?" 
                                onConfirm={() => deleteCode(a)}
                                okText="Si" 
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>,
            key: "delete"
        }       
    ]

    return(
        <div className='code'>
            <div className='div-page'>
                <div><h1>Codigos</h1></div>
                <Row>
                    <Col className='add-code'>
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item
                                label='Codigo'
                                name="code"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit">
                                    Agregar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Table dataSource={codes} columns={columns}/>
            </div>
        </div>
    )
}