import React, {useEffect, useContext, useState} from 'react';
import {Button, Form, Input} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import {AppContext} from '../../Provider';
import {loginData} from '../../controller/control'
import Users from '../../Components/Users';
import Admon from '../../Components/Admon'
import Codes from '../../Components/Code'
import Clients from '../../Components/Client'
import './styles.scss'

export default function Admin(){
    const [state, setState] = useContext(AppContext)
    const [page, setPage] = useState(<Admon />)

    const navigate = useNavigate()

    useEffect(() => {
        if(!state.token){
            navigate('/')
        }
    }, [])

    const goToPage = (value) => {
        setPage(value)
    }

    return(
        <div className='admin'>
            <div className='botonesAdmin'>
                <div><Button primary onClick={() => navigate('/')}>SALIR</Button></div>
                <div><Button primary onClick={() => goToPage(<Users />)}>USUARIOS</Button></div>
                <div><Button primary onClick={() => goToPage(<Admon />)}>ENCUESTAS</Button></div>
                <div><Button primary onClick={() => goToPage(<Codes />)}>CODIGOS</Button></div>
                <div><Button primary onClick={() => goToPage(<Clients />)}>CLIENTES</Button></div>
            </div>
            {page}
        </div>
    )
}