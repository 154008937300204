import React, {useState, useContext, useEffect} from 'react';
import {Row, Col, Button, Form, Input, Radio, Select} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {AppContext} from '../../Provider';
import {addData, getData} from '../../controller/control';
import Personal from '../../assets/personal.json';
import TableA from '../../assets/tablaa.json'
import TableAExt from '../../assets/tablaa-ext.json'
import TableAExt2 from '../../assets/tablaa-ext2.json'
import TableB from '../../assets/tablab.json'
import TableBExt from '../../assets/tablab-ext.json'
import Estres from '../../assets/estres.json'
import ExtraLaboral from '../../assets/extralaboral.json'
import './styles.scss'

const Option = Select.Option;
const RadioGroup = Radio.Group;

const InputType = ({ values }) => {
    return (
      <Form.Item
        label={values.pregunta}
        name={values.name}
        rules={[
          {
            required: false,
            message: 'Por favor complete este campo',
          },
        ]}
      >
        <Input />
      </Form.Item>
    );
};

const RadioType = ({ values, onClearSelection }) => {
    return (
        <Form.Item
        label={values.pregunta}
        name={values.name}
        rules={[
            {
            required: false,
            message: 'Por favor complete este campo',
            },
        ]}
        >
            <div style={{display: 'flex'}}>
                <RadioGroup>
                    {values.opciones.map((val, index) => (
                    <Radio key={index} value={val.valor}>
                        {val.opcion}
                    </Radio>
                    ))}
                </RadioGroup>
                <Button onClick={() => onClearSelection(values.name)} type='text' style={{padding: '0px', margin: '0px'}}><CloseCircleOutlined style={{color: 'whitesmoke'}}/></Button>
            </div>
        </Form.Item>
    );
};

const RadioTypeA = ({values, onClearSelection}) => {
    const namesSplit = values.pregunta.split('.')
    const names = 'A'+namesSplit[0]
    return(
        <Form.Item
            label={values.pregunta}
            name={names}
            rules={[
                {
                required: false,
                message: 'Por favor complete este campo',
                },
            ]}
        >
            <div style={{display: 'flex'}}>
                <RadioGroup>
                    {values.opciones.map((val, index) => (
                        <Radio key={index} value={`${val.valor}-${val.opcion}`}>
                            {val.opcion}
                        </Radio>
                    ))}
                </RadioGroup>
                <Button onClick={() => onClearSelection(names)} type='text' style={{padding: '0px', margin: '0px'}}><CloseCircleOutlined style={{color: 'whitesmoke'}}/></Button>
            </div>
        </Form.Item>
    )
}

const RadioTypeB = ({values, onClearSelection}) => {
    const namesSplit = values.pregunta.split('.')
    const names = 'B'+namesSplit[0]
    return(
        <Form.Item
            label={values.pregunta}
            name={names}
            rules={[
                {
                required: false,
                message: 'Por favor complete este campo',
                },
            ]}
        >
            <div style={{display: 'flex'}}>
                <RadioGroup>
                    {values.opciones.map((val, index) => (
                        <Radio key={index} value={`${val.valor}-${val.opcion}`}>
                            {val.opcion}
                        </Radio>
                    ))}
                </RadioGroup>
                <Button onClick={() => onClearSelection(names)} type='text' style={{padding: '0px', margin: '0px'}}><CloseCircleOutlined style={{color: 'whitesmoke'}}/></Button>
            </div>
        </Form.Item>
    )
}

const RadioTypeEx = ({values, onClearSelection}) => {
    const namesSplit = values.pregunta.split('.')
    const names = 'EX'+namesSplit[0]
    return(
        <Form.Item
            label={values.pregunta}
            name={names}
            rules={[
                {
                required: false,
                message: 'Por favor complete este campo',
                },
            ]}
        >
            <div style={{display: 'flex'}}>
                <RadioGroup>
                    {values.opciones.map((val, index) => (
                        <Radio key={index} value={`${val.valor}-${val.opcion}`}>
                            {val.opcion}
                        </Radio>
                    ))}
                </RadioGroup>
                <Button onClick={() => onClearSelection(names)} type='text' style={{padding: '0px', margin: '0px'}}><CloseCircleOutlined style={{color: 'whitesmoke'}}/></Button>
            </div>
        </Form.Item>
    )
}

const RadioTypeEs = ({ values, onClearSelection }) => {
    const namesSplit = values.pregunta.split('.');
    const names = 'ES' + namesSplit[0];

    return (
        <Form.Item
            label={values.pregunta}
            name={names}
            rules={[
                {
                    required: false,
                    message: 'Por favor complete este campo',
                },
            ]}
        >
            <div style={{display: 'flex'}}>
                <RadioGroup>
                    {values.opciones.map((val, index) => (
                        <Radio key={index} value={`${val.valor}-${val.opcion}`}>
                            {val.opcion}
                        </Radio>
                    ))}
                </RadioGroup>
                <Button onClick={() => onClearSelection(names)} type='text' style={{padding: '0px', margin: '0px'}}><CloseCircleOutlined style={{color: 'whitesmoke'}}/></Button>
            </div>
        </Form.Item>
    );
};

const SelectType = ({ values }) => {
    return (
        <Form.Item
        label={values.pregunta}
        name={values.name}
        rules={[
            {
            required: false,
            message: 'Por favor complete este campo',
            },
        ]}
        >
        <Select placeholder="Seleccione">
            {values.select.map((val, index) => (
            <Option key={index} value={val.valor}>
                {val.opcion}
            </Option>
            ))}
        </Select>
        </Form.Item>
    );
};


export default function Home(){
    const [state, setState] = useContext(AppContext)
    const [codes, setCodes] = useState([])
    const [clients, setClients] = useState([])
    const [component, setComponent] = useState('rysquestions')
    const [preguntas, setPreguntas] = useState([])
    const [valid, setValid] = useState(true)
    const [opcion1, setOpcion1] = useState('')
    const [opcion2, setOpcion2] = useState('')
    const [opcion1B, setOpcion1B] = useState('')

    const navigate = useNavigate()
    const [form] = Form.useForm();

    // const addValues = (values) => {
    //     setPreguntas([...preguntas, values])
    // }


    // const organizarObjetos = async(values) => {
    //     var obj = {}
    //     await values.map((res) => {
    //        Object.assign(obj, res)
    //     })
    //     sendForm(obj)
    // }

    const sendForm = async(values) => {
        const addQuestions = await addData(values, `${process.env.REACT_APP_API_URL}/api/builder`, state?.token)
        if(addQuestions.empresa !== ''){
            navigate('/')
        }else{
            alert('ERROR GENERAL, INTENTE ENVIAR NUEVAMENTE')
        }
    }

    // const nextQuestions = () => {
    //     switch (component){
    //         case 'rysquestions':
    //             setComponent('personalquestions')
    //             break;
    //         case 'personalquestions':
    //             if(state.table === 'a'){
    //                 setComponent('tablaaquestions')
    //             }
    //             if(state.table === 'b'){
    //                 setComponent('tablabquestions')
    //             }
    //             break;
    //         case 'tablaaquestions':
    //             setComponent('extraquestions')
    //             break;
    //         case 'tablabquestions':
    //             setComponent('extraquestions')
    //             break;
    //         case 'extraquestions':
    //             setComponent('estresquestions')
    //             break;
    //         case 'estresquestions':
    //             setComponent('final')
    //             break;
    //         case 'final':
    //             organizarObjetos()
    //             break;
    //         default:
    //             console.log('finish')
    //     }

    // }

    const testForm = (values) => {
    var text = 'SEGURO QUIERE ENVIAR LAS RESPUESTAS\nLas preguntas que faltan son: \n'
        for(var key in values){
            if(values[key] === undefined){
                text += (`${key}, `)
            }
        }
        if(window.confirm(text)){
            sendForm(values)
        }
    }

    Object.filter = (obj, predicate) => 
        Object.keys(obj)
              .filter( key => predicate(obj[key]) )
              .reduce( (res, key) => (res[key] = obj[key], res), {} );

    const onFinish = async(values) => {
        const val = Object.filter(values, val => val === undefined)
        if(Object.keys(val).length > 0){
            testForm(values)
        }else{
            sendForm(values)
        }
        // testForm(values)
        // window.scrollTo(0, 0)
        // await addValues(values)
        // nextQuestions()
    }

    const handleChange = (value) => {
        setOpcion1(value.target.value)
    }
 
    const handleChange2 = (value) => {
        setOpcion2(value.target.value)
    }

    const handleChange3 = (value) => {
        setOpcion1B(value.target.value)
    }

    const handleClearSelection = (name) => {
        form.resetFields([name]);
    };

    // const selectComponent = () => {
    //     if(component === 'rysquestions'){
    //         return <RysQuestions datos={onFinish}/>
    //     }
    //     if(component === 'personalquestions'){
    //         return <PersonalQuestions datos={onFinish}/>
    //     }
    //     if(component === 'tablaaquestions'){
    //         return <TableAQuestions datos={onFinish}/>
    //     }
    //     if(component === 'tablabquestions'){
    //         return <TableBQuestions datos={onFinish}/>
    //     }
    //     if(component === 'estresquestions'){
    //         return <EstresQuestions datos={onFinish}/>
    //     }
    //     if(component === 'extraquestions'){
    //         return <ExtraQuestions datos={onFinish}/>
    //     }
    //     if(component === 'final'){
    //         return <Final datos={onFinish}/>
    //     }
    // }
    const getClients = async() => {
        const arrayCodes = await getData(`${process.env.REACT_APP_API_URL}/api/client/`, state?.token)
        if(arrayCodes && arrayCodes.length > 0){
            setClients(arrayCodes)
        }
    }

    const getCodes = async() => {
        const arrayCodes = await getData(`${process.env.REACT_APP_API_URL}/api/code/`, state?.token)
        if(arrayCodes && arrayCodes.length > 0){
            setCodes(arrayCodes)
            getClients()
        }
    }


    useEffect(() => {
        getCodes()
    }, [0])

    return(
        <div className='home'>
            <div className="title">
                <h1>DIAGNOSTICO DE FACTORES DE RIESGO PSICOSOCIAL</h1>
                <p>Resolucion 2646 de 2008</p>
            </div>
            <Row className='description'>
                <Col xs={20} lg={16}>
                    <p>
                        Este cuestionario de factores psicosociales busca conocer su opinión sobre algunos aspectos de su trabajo.
                    </p>
                    <p>
                        Le agradecemos que usted se sirva de contestar a las siguientes preguntas de forma absolutamente sincera. Las respuestas que usted de al cuestionario, no son ni buenas, ni malas, lo importante es que reflejen su manera de pensar sobre su trabajo.
                    </p>
                    <p>
                        Al responder por favor lea cuidadosamente cada pregunta, luego piense como es su trabajo y responda a todas las preguntas, en cada una de ellas marque una sola respuesta. Señale la casilla de la respuesta que refleje mejor su trabajo.
                    </p>
                    <p>
                        Tenga presente que el cuestionario NO lo evalúa a usted como trabajador, sino busca conocer cómo es el trabajo que le han asignado.
                    </p>
                </Col>
            </Row>
            {/* {selectComponent()} */}
            <Row className='forms'>
                <Col xs={22} md={18} lg={16}>
                    <Form
                        form={form}
                        name="basic"
                        layout="horizontal"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Cliente"
                            name="cliente"
                            rules={[
                                {
                                required: true,
                                message: 'Por favor complete este campo',
                                },
                            ]}
                        >
                            <Select defaultValue="Seleccione el cliente">
                                {clients.map((code, key) => {
                                    return(
                                        <Option value={code._id} key={key}>{code.client}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Código"
                            name="codigo"
                            rules={[
                                {
                                required: true,
                                message: 'Por favor complete este campo',
                                },
                            ]}
                        >
                            <Select defaultValue="Seleccione el código">
                                {codes.map((code, key) => {
                                    return(
                                        <Option value={code.code} key={key}>{code.code}</Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                        {Personal.personales[0].preguntas.map((personal, key) => {
                            if (personal.tipo === 'input') {
                                return <InputType key={key} values={personal} />;
                            }
                            if (personal.tipo === 'radio') {
                                return (
                                <RadioType
                                    key={key}
                                    values={personal}
                                    onClearSelection={handleClearSelection}
                                />
                                );
                            }
                            if (personal.tipo === 'select') {
                                return <SelectType key={key} values={personal} />;
                            }
                            return null;
                        })}
                        {state?.table === 'a' &&
                            <>{TableA.test.map((test, key) => {
                                return(
                                    <div>
                                        <div className='title'><h2>{test.nombre}</h2></div>
                                        <div>
                                            {test.preguntas.map((preg, index) => (
                                                <RadioTypeA
                                                    key={index}
                                                    values={preg}
                                                    onClearSelection={handleClearSelection}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )
                            })}
                            <Form.Item
                                label="En mi trabajo debo brindar servicio a clientes o usuarios:"
                                name="servicioclienteA"
                                rules={[
                                    {
                                    required: false,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <RadioGroup onChange={handleChange}>
                                    <Radio value="si">Si</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            </Form.Item>
                            {opcion1 === 'si' && (
                                TableAExt.test.map((test, key) => {
                                    return(
                                        <div>
                                            <div className='title'><h2>{test.nombre}</h2></div>
                                            <div>
                                                {test.preguntas.map((preg2, index2) => (
                                                    <RadioTypeA
                                                        key={index2}
                                                        values={preg2}
                                                        onClearSelection={handleClearSelection}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                            <Form.Item
                                label="Soy jefe de otras personas en mi trabajo:"
                                name="jefetrabajoA"
                                rules={[
                                    {
                                    required: false,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <RadioGroup onChange={handleChange2}>
                                    <Radio value="si">Si</Radio>
                                    <Radio value="no">No</Radio>
                                </RadioGroup>
                            </Form.Item>
                            {opcion2 === 'si' && (
                                TableAExt2.test.map((test, key) => {
                                    return(
                                        <div>
                                            <div className='title'><h2>{test.nombre}</h2></div>
                                            <div>
                                                {test.preguntas.map((preg3, index3) => (
                                                    <RadioTypeA
                                                        key={index3}
                                                        values={preg3}
                                                        onClearSelection={handleClearSelection}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })
                            )}</>
                        }
                        {state?.table === 'b' &&
                            <>
                                {TableB.test.map((test, key) => {
                                    return(
                                        <div>
                                            <div className='title'><h2>{test.nombre}</h2></div>
                                            <div>
                                                {test.preguntas.map((preg, index) => (
                                                    <RadioTypeB
                                                        key={index}
                                                        values={preg}
                                                        onClearSelection={handleClearSelection}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )
                                })}
                                <Form.Item
                                    label="En mi trabajo debo brindar servicio a clientes o usuarios:"
                                    name="servicioclienteB"
                                    rules={[
                                        {
                                        required: false,
                                        message: 'Por favor complete este campo',
                                        },
                                    ]}
                                >
                                    <RadioGroup onChange={handleChange3}>
                                        <Radio value="si">Si</Radio>
                                        <Radio value="no">No</Radio>
                                    </RadioGroup>
                                </Form.Item>
                                {opcion1B === 'si' && (
                                    TableBExt.test.map((test, key) => {
                                        return(
                                            <div>
                                                <div className='title'><h2>{test.nombre}</h2></div>
                                                <div>
                                                    {test.preguntas.map((preg2, index2) => (
                                                        <RadioTypeB
                                                            key={index2}
                                                            values={preg2}
                                                            onClearSelection={handleClearSelection}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </>
                        }
                        {ExtraLaboral.test.map((test, key) => {
                            return(
                                <div>
                                    <div className='title'><h2>{test.nombre}</h2></div>
                                    <div>
                                        {test.preguntas.map((preg, index) => (
                                            <RadioTypeEx
                                                key={index}
                                                values={preg}
                                                onClearSelection={handleClearSelection}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                        {Estres.test.map((test, key) => (
                            <div key={key}>
                                <div className='title'><h2>{test.nombre}</h2></div>
                                <div>
                                    {test.preguntas.map((preg, index) => (
                                        <RadioTypeEs
                                            key={index}
                                            values={preg}
                                            onClearSelection={handleClearSelection}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                        <Form.Item style={{textAlign: 'end'}}>
                            <Button ghost htmlType="submit">
                                Siguiente
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}