import React, {useContext} from 'react';
import {Button, Form, Input} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import {AppContext} from '../../Provider';
import {loginData} from '../../controller/control'
import './styles.scss'
export default function LoginAdmin(){
    const [state, setState] = useContext(AppContext)

    const navigate = useNavigate()

    const onFinish = async(values) => {
        const log = await loginData(values, `${process.env.REACT_APP_API_URL}/api/admin/login`)
        if(log && log.length > 0){
            setState({user: log[0].user, id: log[0]._id, token: log[1].token})
            navigate('/admin')
        }else{
            alert('Error de usuario o contraseña')
        }
    }

    return(
        <div className="login-admin">
            <div className="title">
                <h1>ADMINISTRADOR</h1>
            </div>
            <div className='form'>
                <div>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={<label style={{color: 'whitesmoke'}}>Usuario</label>}
                            name="user"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your username!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={<label style={{color: 'whitesmoke'}}>Contraseña</label>}
                            name="pass"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your password!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button ghost htmlType="submit">
                                Entrar
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button ghost>
                                <Link to="/">Cancelar</Link>
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}