import React, {useEffect, useContext, useState} from 'react';
import {Button, Form, Input, Table, Row, Col, Popconfirm} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {AppContext} from '../../Provider';
import './styles.scss'
import { addData, deleteData, getData } from '../../controller/control';

export default function Clients(){
    const [state, setState] = useContext(AppContext)
    const [clients, setClients] = useState([])
    const [form] = Form.useForm();

    const navigate = useNavigate()

    const getClients = async() => {
        const arrayClients = await getData(`${process.env.REACT_APP_API_URL}/api/client/`, state?.token)
        if((arrayClients && arrayClients.length >= 0)){
            setClients(arrayClients)
        }
    }

    useEffect(() => {
        getClients()
    }, [0])

    const deleteClient = async(value) => {
        const deleteArrayClient = await deleteData(`${process.env.REACT_APP_API_URL}/api/client/${value._id}`, state?.token)
        if(deleteArrayClient === 200){
            getClients()
        }
    }

    const onFinish = async(values) => {
        const addClient = await addData(values, `${process.env.REACT_APP_API_URL}/api/client/`, state?.token)
        if(addClient && addClient._id){
            getClients()
            form.resetFields()
        }else{
            alert('Error General')
        }
    }

    const columns = [
        {
          title: "Cliente",
          dataIndex: "client",
          key: "client"
        },
        {
            title: "Eliminar",
            render: (a) =>  <Popconfirm 
                                title="Seguro quiere borrar este Cliente?" 
                                onConfirm={() => deleteClient(a)}
                                okText="Si" 
                                cancelText="No"
                            >
                                <DeleteOutlined />
                            </Popconfirm>,
            key: "delete"
        }       
    ]

    return(
        <div className='client'>
            <div className='div-page'>
                <div><h1>Clientes</h1></div>
                <Row>
                    <Col className='add-client'>
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item
                                label='Cliente'
                                name="client"
                                rules={[
                                    {
                                    required: true,
                                    message: 'Por favor complete este campo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button htmlType="submit">
                                    Agregar
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Table dataSource={clients} columns={columns}/>
            </div>
        </div>
    )
}