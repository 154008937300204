import React, {useEffect, useContext, useState} from 'react';
import {Button, Form, Input, Table, Row, Col, Popconfirm, Select} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import {AppContext} from '../../Provider';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './styles.scss'
import { addData, deleteData, getData } from '../../controller/control';
import moment from 'moment'
import 'moment/locale/es'; 

const Option = Select.Option;

export default function Admon(){
    const [state, setState] = useContext(AppContext)
    const [answers, setAnswers] = useState([])
    const [form] = Form.useForm();
    const [indice, setIndice] = useState(1)
    const [idClient, setIdClient] = useState('')
    const [clients, setClients] = useState([])

    useEffect(() => {
        if(idClient && idClient !== '')
            getAnswers()
    }, [idClient])

    const navigate = useNavigate()
    let datosExcel

    const getClients = async() => {
        const arrayClients = await getData(`${process.env.REACT_APP_API_URL}/api/client/`, state?.token)
        if((arrayClients && arrayClients.length >= 0)){
            setClients(arrayClients)
        }
    }

    const handleChangeClient = (idClient) => {
        setIdClient(idClient)
    }

    const getAnswers = async() => {
        const arrayAnswers = await getData(`${process.env.REACT_APP_API_URL}/api/builder/${idClient}`, state?.token)
        if(arrayAnswers && arrayAnswers.length >= 0){
            setAnswers(arrayAnswers)
        }
    }

    const formatArray = () => {
        var array = []
        answers.map((x) => {
            var obj = {
                fecha: moment(x.createdAt).format("MM-DD-YYYY HH:mm:ss"),
                cliente: transform(x.cliente),
                empresa: x.empresa,
                codigo: x.codigo,
                nombre: x.nombre,
                cedula: x.cedula,
                sexo: x.sexo,
                nacimiento: x.nacimiento,
                estadocivil: x.estadocivil,
                estudios: x.estudios,
                ocupacion: x.ocupacion,
                ciudad: x.ciudad,
                departamento: x.departamento,
                estrato: x.estrato,
                tipovivienda: x.tipovivienda,
                dependientes: x.dependientes,
                ciudadtrabajo: x.ciudadtrabajo,
                departamentotrabajo: x.departamentotrabajo,
                tiempotrabajo: x.tiempotrabajo,
                cargo: x.cargo,
                tipocargo: x.tipocargo,
                tiempocargo: x.tiempocargo,
                area: x.area,
                contrato: x.contrato,
                horastrabajo: x.horastrabajo,
                tiposalario: x.tiposalario,
                A1: x.A1 === null ? null : x.A1.split('-')[indice],
                A2: x.A2 === null ? null : x.A2.split('-')[indice],
                A3: x.A3 === null ? null : x.A3.split('-')[indice],
                A4: x.A4 === null ? null : x.A4.split('-')[indice],
                A5: x.A5 === null ? null : x.A5.split('-')[indice],
                A6: x.A6 === null ? null : x.A6.split('-')[indice],
                A7: x.A7 === null ? null : x.A7.split('-')[indice],
                A8: x.A8 === null ? null : x.A8.split('-')[indice],
                A9: x.A9 === null ? null : x.A9.split('-')[indice],
                A10: x.A10 === null ? null : x.A10.split('-')[indice],
                A11: x.A11 === null ? null : x.A11.split('-')[indice],
                A12: x.A12 === null ? null : x.A12.split('-')[indice],
                A13: x.A13 === null ? null : x.A13.split('-')[indice],
                A14: x.A14 === null ? null : x.A14.split('-')[indice],
                A15: x.A15 === null ? null : x.A15.split('-')[indice],
                A16: x.A16 === null ? null : x.A16.split('-')[indice],
                A17: x.A17 === null ? null : x.A17.split('-')[indice],
                A18: x.A18 === null ? null : x.A18.split('-')[indice],
                A19: x.A19 === null ? null : x.A19.split('-')[indice],
                A20: x.A20 === null ? null : x.A20.split('-')[indice],
                A21: x.A21 === null ? null : x.A21.split('-')[indice],
                A22: x.A22 === null ? null : x.A22.split('-')[indice],
                A23: x.A23 === null ? null : x.A23.split('-')[indice],
                A24: x.A24 === null ? null : x.A24.split('-')[indice],
                A25: x.A25 === null ? null : x.A25.split('-')[indice],
                A26: x.A26 === null ? null : x.A26.split('-')[indice],
                A27: x.A27 === null ? null : x.A27.split('-')[indice],
                A28: x.A28 === null ? null : x.A28.split('-')[indice],
                A29: x.A29 === null ? null : x.A29.split('-')[indice],
                A30: x.A30 === null ? null : x.A30.split('-')[indice],
                A31: x.A31 === null ? null : x.A31.split('-')[indice],
                A32: x.A32 === null ? null : x.A32.split('-')[indice],
                A33: x.A33 === null ? null : x.A33.split('-')[indice],
                A34: x.A34 === null ? null : x.A34.split('-')[indice],
                A35: x.A35 === null ? null : x.A35.split('-')[indice],
                A36: x.A36 === null ? null : x.A36.split('-')[indice],
                A37: x.A37 === null ? null : x.A37.split('-')[indice],
                A38: x.A38 === null ? null : x.A38.split('-')[indice],
                A39: x.A39 === null ? null : x.A39.split('-')[indice],
                A40: x.A40 === null ? null : x.A40.split('-')[indice],
                A41: x.A41 === null ? null : x.A41.split('-')[indice],
                A42: x.A42 === null ? null : x.A42.split('-')[indice],
                A43: x.A43 === null ? null : x.A43.split('-')[indice],
                A44: x.A44 === null ? null : x.A44.split('-')[indice],
                A45: x.A45 === null ? null : x.A45.split('-')[indice],
                A46: x.A46 === null ? null : x.A46.split('-')[indice],
                A47: x.A47 === null ? null : x.A47.split('-')[indice],
                A48: x.A48 === null ? null : x.A48.split('-')[indice],
                A49: x.A49 === null ? null : x.A49.split('-')[indice],
                A50: x.A50 === null ? null : x.A50.split('-')[indice],
                A51: x.A51 === null ? null : x.A51.split('-')[indice],
                A52: x.A52 === null ? null : x.A52.split('-')[indice],
                A53: x.A53 === null ? null : x.A53.split('-')[indice],
                A54: x.A54 === null ? null : x.A54.split('-')[indice],
                A55: x.A55 === null ? null : x.A55.split('-')[indice],
                A56: x.A56 === null ? null : x.A56.split('-')[indice],
                A57: x.A57 === null ? null : x.A57.split('-')[indice],
                A58: x.A58 === null ? null : x.A58.split('-')[indice],
                A59: x.A59 === null ? null : x.A59.split('-')[indice],
                A60: x.A60 === null ? null : x.A60.split('-')[indice],
                A61: x.A61 === null ? null : x.A61.split('-')[indice],
                A62: x.A62 === null ? null : x.A62.split('-')[indice],
                A63: x.A63 === null ? null : x.A63.split('-')[indice],
                A64: x.A64 === null ? null : x.A64.split('-')[indice],
                A65: x.A65 === null ? null : x.A65.split('-')[indice],
                A66: x.A66 === null ? null : x.A66.split('-')[indice],
                A67: x.A67 === null ? null : x.A67.split('-')[indice],
                A68: x.A68 === null ? null : x.A68.split('-')[indice],
                A69: x.A69 === null ? null : x.A69.split('-')[indice],
                A70: x.A70 === null ? null : x.A70.split('-')[indice],
                A71: x.A71 === null ? null : x.A71.split('-')[indice],
                A72: x.A72 === null ? null : x.A72.split('-')[indice],
                A73: x.A73 === null ? null : x.A73.split('-')[indice],
                A74: x.A74 === null ? null : x.A74.split('-')[indice],
                A75: x.A75 === null ? null : x.A75.split('-')[indice],
                A76: x.A76 === null ? null : x.A76.split('-')[indice],
                A77: x.A77 === null ? null : x.A77.split('-')[indice],
                A78: x.A78 === null ? null : x.A78.split('-')[indice],
                A79: x.A79 === null ? null : x.A79.split('-')[indice],
                A80: x.A80 === null ? null : x.A80.split('-')[indice],
                A81: x.A81 === null ? null : x.A81.split('-')[indice],
                A82: x.A82 === null ? null : x.A82.split('-')[indice],
                A83: x.A83 === null ? null : x.A83.split('-')[indice],
                A84: x.A84 === null ? null : x.A84.split('-')[indice],
                A85: x.A85 === null ? null : x.A85.split('-')[indice],
                A86: x.A86 === null ? null : x.A86.split('-')[indice],
                A87: x.A87 === null ? null : x.A87.split('-')[indice],
                A88: x.A88 === null ? null : x.A88.split('-')[indice],
                A89: x.A89 === null ? null : x.A89.split('-')[indice],
                A90: x.A90 === null ? null : x.A90.split('-')[indice],
                A91: x.A91 === null ? null : x.A91.split('-')[indice],
                A92: x.A92 === null ? null : x.A92.split('-')[indice],
                A93: x.A93 === null ? null : x.A93.split('-')[indice],
                A94: x.A94 === null ? null : x.A94.split('-')[indice],
                A95: x.A95 === null ? null : x.A95.split('-')[indice],
                A96: x.A96 === null ? null : x.A96.split('-')[indice],
                A97: x.A97 === null ? null : x.A97.split('-')[indice],
                A98: x.A98 === null ? null : x.A98.split('-')[indice],
                A99: x.A99 === null ? null : x.A99.split('-')[indice],
                A100: x.A100 === null ? null : x.A100.split('-')[indice],
                A101: x.A101 === null ? null : x.A101.split('-')[indice],
                A102: x.A102 === null ? null : x.A102.split('-')[indice],
                A103: x.A103 === null ? null : x.A103.split('-')[indice],
                A104: x.A104 === null ? null : x.A104.split('-')[indice],
                A105: x.A105 === null ? null : x.A105.split('-')[indice],
                servicioclienteA: x.servicioclienteA,
                A106: x.A106 === null ? null : x.A106.split('-')[indice],
                A107: x.A107 === null ? null : x.A107.split('-')[indice],
                A108: x.A108 === null ? null : x.A108.split('-')[indice],
                A109: x.A109 === null ? null : x.A109.split('-')[indice],
                A110: x.A110 === null ? null : x.A110.split('-')[indice],
                A111: x.A111 === null ? null : x.A111.split('-')[indice],
                A112: x.A112 === null ? null : x.A112.split('-')[indice],
                A113: x.A113 === null ? null : x.A113.split('-')[indice],
                A114: x.A114 === null ? null : x.A114.split('-')[indice],
                jefetrabajoA: x.jefetrabajoA,
                A115: x.A115 === null ? null : x.A115.split('-')[indice],
                A116: x.A116 === null ? null : x.A116.split('-')[indice],
                A117: x.A117 === null ? null : x.A117.split('-')[indice],
                A118: x.A118 === null ? null : x.A118.split('-')[indice],
                A119: x.A119 === null ? null : x.A119.split('-')[indice],
                A120: x.A120 === null ? null : x.A120.split('-')[indice],
                A121: x.A121 === null ? null : x.A121.split('-')[indice],
                A122: x.A122 === null ? null : x.A122.split('-')[indice],
                A123: x.A123 === null ? null : x.A123.split('-')[indice],
                B1: x.B1 === null ? null : x.B1.split('-')[indice],
                B2: x.B2 === null ? null : x.B2.split('-')[indice],
                B3: x.B3 === null ? null : x.B3.split('-')[indice],
                B4: x.B4 === null ? null : x.B4.split('-')[indice],
                B5: x.B5 === null ? null : x.B5.split('-')[indice],
                B6: x.B6 === null ? null : x.B6.split('-')[indice],
                B7: x.B7 === null ? null : x.B7.split('-')[indice],
                B8: x.B8 === null ? null : x.B8.split('-')[indice],
                B9: x.B9 === null ? null : x.B9.split('-')[indice],
                B10: x.B10 === null ? null : x.B10.split('-')[indice],
                B11: x.B11 === null ? null : x.B11.split('-')[indice],
                B12: x.B12 === null ? null : x.B12.split('-')[indice],
                B13: x.B13 === null ? null : x.B13.split('-')[indice],
                B14: x.B14 === null ? null : x.B14.split('-')[indice],
                B15: x.B15 === null ? null : x.B15.split('-')[indice],
                B16: x.B16 === null ? null : x.B16.split('-')[indice],
                B17: x.B17 === null ? null : x.B17.split('-')[indice],
                B18: x.B18 === null ? null : x.B18.split('-')[indice],
                B19: x.B19 === null ? null : x.B19.split('-')[indice],
                B20: x.B20 === null ? null : x.B20.split('-')[indice],
                B21: x.B21 === null ? null : x.B21.split('-')[indice],
                B22: x.B22 === null ? null : x.B22.split('-')[indice],
                B23: x.B23 === null ? null : x.B23.split('-')[indice],
                B24: x.B24 === null ? null : x.B24.split('-')[indice],
                B25: x.B25 === null ? null : x.B25.split('-')[indice],
                B26: x.B26 === null ? null : x.B26.split('-')[indice],
                B27: x.B27 === null ? null : x.B27.split('-')[indice],
                B28: x.B28 === null ? null : x.B28.split('-')[indice],
                B29: x.B29 === null ? null : x.B29.split('-')[indice],
                B30: x.B30 === null ? null : x.B30.split('-')[indice],
                B31: x.B31 === null ? null : x.B31.split('-')[indice],
                B32: x.B32 === null ? null : x.B32.split('-')[indice],
                B33: x.B33 === null ? null : x.B33.split('-')[indice],
                B34: x.B34 === null ? null : x.B34.split('-')[indice],
                B35: x.B35 === null ? null : x.B35.split('-')[indice],
                B36: x.B36 === null ? null : x.B36.split('-')[indice],
                B37: x.B37 === null ? null : x.B37.split('-')[indice],
                B38: x.B38 === null ? null : x.B38.split('-')[indice],
                B39: x.B39 === null ? null : x.B39.split('-')[indice],
                B40: x.B40 === null ? null : x.B40.split('-')[indice],
                B41: x.B41 === null ? null : x.B41.split('-')[indice],
                B42: x.B42 === null ? null : x.B42.split('-')[indice],
                B43: x.B43 === null ? null : x.B43.split('-')[indice],
                B44: x.B44 === null ? null : x.B44.split('-')[indice],
                B45: x.B45 === null ? null : x.B45.split('-')[indice],
                B46: x.B46 === null ? null : x.B46.split('-')[indice],
                B47: x.B47 === null ? null : x.B47.split('-')[indice],
                B48: x.B48 === null ? null : x.B48.split('-')[indice],
                B49: x.B49 === null ? null : x.B49.split('-')[indice],
                B50: x.B50 === null ? null : x.B50.split('-')[indice],
                B51: x.B51 === null ? null : x.B51.split('-')[indice],
                B52: x.B52 === null ? null : x.B52.split('-')[indice],
                B53: x.B53 === null ? null : x.B53.split('-')[indice],
                B54: x.B54 === null ? null : x.B54.split('-')[indice],
                B55: x.B55 === null ? null : x.B55.split('-')[indice],
                B56: x.B56 === null ? null : x.B56.split('-')[indice],
                B57: x.B57 === null ? null : x.B57.split('-')[indice],
                B58: x.B58 === null ? null : x.B58.split('-')[indice],
                B59: x.B59 === null ? null : x.B59.split('-')[indice],
                B60: x.B60 === null ? null : x.B60.split('-')[indice],
                B61: x.B61 === null ? null : x.B61.split('-')[indice],
                B62: x.B62 === null ? null : x.B62.split('-')[indice],
                B63: x.B63 === null ? null : x.B63.split('-')[indice],
                B64: x.B64 === null ? null : x.B64.split('-')[indice],
                B65: x.B65 === null ? null : x.B65.split('-')[indice],
                B66: x.B66 === null ? null : x.B66.split('-')[indice],
                B67: x.B67 === null ? null : x.B67.split('-')[indice],
                B68: x.B68 === null ? null : x.B68.split('-')[indice],
                B69: x.B69 === null ? null : x.B69.split('-')[indice],
                B70: x.B70 === null ? null : x.B70.split('-')[indice],
                B71: x.B71 === null ? null : x.B71.split('-')[indice],
                B72: x.B72 === null ? null : x.B72.split('-')[indice],
                B73: x.B73 === null ? null : x.B73.split('-')[indice],
                B74: x.B74 === null ? null : x.B74.split('-')[indice],
                B75: x.B75 === null ? null : x.B75.split('-')[indice],
                B76: x.B76 === null ? null : x.B76.split('-')[indice],
                B77: x.B77 === null ? null : x.B77.split('-')[indice],
                B78: x.B78 === null ? null : x.B78.split('-')[indice],
                B79: x.B79 === null ? null : x.B79.split('-')[indice],
                B80: x.B80 === null ? null : x.B80.split('-')[indice],
                B81: x.B81 === null ? null : x.B81.split('-')[indice],
                B82: x.B82 === null ? null : x.B82.split('-')[indice],
                B83: x.B83 === null ? null : x.B83.split('-')[indice],
                B84: x.B84 === null ? null : x.B84.split('-')[indice],
                B85: x.B85 === null ? null : x.B85.split('-')[indice],
                B86: x.B86 === null ? null : x.B86.split('-')[indice],
                B87: x.B87 === null ? null : x.B87.split('-')[indice],
                B88: x.B88 === null ? null : x.B88.split('-')[indice],
                servicioclienteB: x.servicioclienteB,
                B89: x.B89 === null ? null : x.B89.split('-')[indice],
                B90: x.B90 === null ? null : x.B90.split('-')[indice],
                B91: x.B91 === null ? null : x.B91.split('-')[indice],
                B92: x.B92 === null ? null : x.B92.split('-')[indice],
                B93: x.B93 === null ? null : x.B93.split('-')[indice],
                B94: x.B94 === null ? null : x.B94.split('-')[indice],
                B95: x.B95 === null ? null : x.B95.split('-')[indice],
                B96: x.B96 === null ? null : x.B96.split('-')[indice],
                B97: x.B97 === null ? null : x.B97.split('-')[indice],
                EX1: x.EX1 === null ? null : x.EX1.split('-')[indice],
                EX2: x.EX2 === null ? null : x.EX2.split('-')[indice],
                EX3: x.EX3 === null ? null : x.EX3.split('-')[indice],
                EX4: x.EX4 === null ? null : x.EX4.split('-')[indice],
                EX5: x.EX5 === null ? null : x.EX5.split('-')[indice],
                EX6: x.EX6 === null ? null : x.EX6.split('-')[indice],
                EX7: x.EX7 === null ? null : x.EX7.split('-')[indice],
                EX8: x.EX8 === null ? null : x.EX8.split('-')[indice],
                EX9: x.EX9 === null ? null : x.EX9.split('-')[indice],
                EX10: x.EX10 === null ? null : x.EX10.split('-')[indice],
                EX11: x.EX11 === null ? null : x.EX11.split('-')[indice],
                EX12: x.EX12 === null ? null : x.EX12.split('-')[indice],
                EX13: x.EX13 === null ? null : x.EX13.split('-')[indice],
                EX14: x.EX14 === null ? null : x.EX14.split('-')[indice],
                EX15: x.EX15 === null ? null : x.EX15.split('-')[indice],
                EX16: x.EX16 === null ? null : x.EX16.split('-')[indice],
                EX17: x.EX17 === null ? null : x.EX17.split('-')[indice],
                EX18: x.EX18 === null ? null : x.EX18.split('-')[indice],
                EX19: x.EX19 === null ? null : x.EX19.split('-')[indice],
                EX20: x.EX20 === null ? null : x.EX20.split('-')[indice],
                EX21: x.EX21 === null ? null : x.EX21.split('-')[indice],
                EX22: x.EX22 === null ? null : x.EX22.split('-')[indice],
                EX23: x.EX23 === null ? null : x.EX23.split('-')[indice],
                EX24: x.EX24 === null ? null : x.EX24.split('-')[indice],
                EX25: x.EX25 === null ? null : x.EX25.split('-')[indice],
                EX26: x.EX26 === null ? null : x.EX26.split('-')[indice],
                EX27: x.EX27 === null ? null : x.EX27.split('-')[indice],
                EX28: x.EX28 === null ? null : x.EX28.split('-')[indice],
                EX29: x.EX29 === null ? null : x.EX29.split('-')[indice],
                EX30: x.EX30 === null ? null : x.EX30.split('-')[indice],
                EX31: x.EX31 === null ? null : x.EX31.split('-')[indice],
                ES1: x.ES1 === null ? null : x.ES1.split('-')[indice],
                ES2: x.ES2 === null ? null : x.ES2.split('-')[indice],
                ES3: x.ES3 === null ? null : x.ES3.split('-')[indice],
                ES4: x.ES4 === null ? null : x.ES4.split('-')[indice],
                ES5: x.ES5 === null ? null : x.ES5.split('-')[indice],
                ES6: x.ES6 === null ? null : x.ES6.split('-')[indice],
                ES7: x.ES7 === null ? null : x.ES7.split('-')[indice],
                ES8: x.ES8 === null ? null : x.ES8.split('-')[indice],
                ES9: x.ES9 === null ? null : x.ES9.split('-')[indice],
                ES10: x.ES10 === null ? null : x.ES10.split('-')[indice],
                ES11: x.ES11 === null ? null : x.ES11.split('-')[indice],
                ES12: x.ES12 === null ? null : x.ES12.split('-')[indice],
                ES13: x.ES13 === null ? null : x.ES13.split('-')[indice],
                ES14: x.ES14 === null ? null : x.ES14.split('-')[indice],
                ES15: x.ES15 === null ? null : x.ES15.split('-')[indice],
                ES16: x.ES16 === null ? null : x.ES16.split('-')[indice],
                ES17: x.ES17 === null ? null : x.ES17.split('-')[indice],
                ES18: x.ES18 === null ? null : x.ES18.split('-')[indice],
                ES19: x.ES19 === null ? null : x.ES19.split('-')[indice],
                ES20: x.ES20 === null ? null : x.ES20.split('-')[indice],
                ES21: x.ES21 === null ? null : x.ES21.split('-')[indice],
                ES22: x.ES22 === null ? null : x.ES22.split('-')[indice],
                ES23: x.ES23 === null ? null : x.ES23.split('-')[indice],
                ES24: x.ES24 === null ? null : x.ES24.split('-')[indice],
                ES25: x.ES25 === null ? null : x.ES25.split('-')[indice],
                ES26: x.ES26 === null ? null : x.ES26.split('-')[indice],
                ES27: x.ES27 === null ? null : x.ES27.split('-')[indice],
                ES28: x.ES28 === null ? null : x.ES28.split('-')[indice],
                ES29: x.ES29 === null ? null : x.ES29.split('-')[indice],
                ES30: x.ES30 === null ? null : x.ES30.split('-')[indice],
                ES31: x.ES31 === null ? null : x.ES31.split('-')[indice],
            }
            array.push(obj)
        })
        exportToExcel(array)
    }

    const exportToExcel = (values) => {
        const worksheet = XLSX.utils.json_to_sheet(values);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        saveAs(blob, "data.xlsx");
    };

    useEffect(() => {
        getClients()
    }, [0])

    function transform(id){
        const client = clients.find((x) => x._id === id);
        return client ? client.client : 'Cliente no encontrado';
    }

    const columns = [
        {
            title: "fecha",
            render: (a) => moment(a.createdAt).format("MM-DD-YYYY HH:mm:ss"),
            key: "fecha"
        },
        {
            title: "cliente",
            render: (a) => transform(a.cliente),
            key: "cliente"
        },
        {
            title: "empresa",
            dataIndex: "empresa",
            key: "empresa"
        },
        {
            title: "codigo",
            dataIndex: "codigo",
            key: "codigo"
        },
        {
            title: "nombre",
            dataIndex: "nombre",
            key: "nombre"
        },
        {
            title: "cedula",
            dataIndex: "cedula",
            key: "cedula"
        },
        {
            title: "sexo",
            dataIndex: "sexo",
            key: "sexo"
        },
        {
            title: "nacimiento",
            dataIndex: "nacimiento",
            key: "nacimiento"
        },
        {
            title: "estadocivil",
            dataIndex: "estadocivil",
            key: "estadocivil"
        },
        {
            title: "estudios",
            dataIndex: "estudios",
            key: "estudios"
        },
        {
            title: "ocupacion",
            dataIndex: "ocupacion",
            key: "ocupacion"
        },
        {
            title: "ciudad",
            dataIndex: "ciudad",
            key: "ciudad"
        },
        {
            title: "departamento",
            dataIndex: "departamento",
            key: "departamento"
        },
        {
            title: "estrato",
            dataIndex: "estrato",
            key: "estrato"
        },
        {
            title: "tipovivienda",
            dataIndex: "tipovivienda",
            key: "tipovivienda"
        },
        {
            title: "dependientes",
            dataIndex: "dependientes",
            key: "dependientes"
        },
        {
            title: "ciudadtrabajo",
            dataIndex: "ciudadtrabajo",
            key: "ciudadtrabajo"
        },
        {
            title: "departamentotrabajo",
            dataIndex: "departamentotrabajo",
            key: "departamentotrabajo"
        },
        {
            title: "tiempotrabajo",
            dataIndex: "tiempotrabajo",
            key: "tiempotrabajo"
        },
        {
            title: "cargo",
            dataIndex: "cargo",
            key: "cargo"
        },
        {
            title: "tipocargo",
            dataIndex: "tipocargo",
            key: "tipocargo"
        },
        {
            title: "tiempocargo",
            dataIndex: "tiempocargo",
            key: "tiempocargo"
        },
        {
            title: "area",
            dataIndex: "area",
            key: "area"
        },
        {
            title: "contrato",
            dataIndex: "contrato",
            key: "contrato"
        },
        {
            title: "horastrabajo",
            dataIndex: "horastrabajo",
            key: "horastrabajo"
        },
        {
            title: "tiposalario",
            dataIndex: "tiposalario",
            key: "tiposalario"
        },
        {
            title: "A1",
            render: (a) => a.A1 === null ? null : a.A1.split('-')[indice],
            key: "A1"
        },
        {
            title: "A2",
            render: (a) => a.A2 === null ? null : a.A2.split('-')[indice],
            key: "A2"
        },
        {
            title: "A3",
            render: (a) => a.A3 === null ? null : a.A3.split('-')[indice],
            key: "A3"
        },
        {
            title: "A4",
            render: (a) => a.A4 === null ? null : a.A4.split('-')[indice],
            key: "A4"
        },
        {
            title: "A5",
            render: (a) => a.A5 === null ? null : a.A5.split('-')[indice],
            key: "A5"
        },
        {
            title: "A6",
            render: (a) => a.A6 === null ? null : a.A6.split('-')[indice],
            key: "A6"
        },
        {
            title: "A7",
            render: (a) => a.A7 === null ? null : a.A7.split('-')[indice],
            key: "A7"
        },
        {
            title: "A8",
            render: (a) => a.A8 === null ? null : a.A8.split('-')[indice],
            key: "A8"
        },
        {
            title: "A9",
            render: (a) => a.A9 === null ? null : a.A9.split('-')[indice],
            key: "A9"
        },
        {
            title: "A10",
            render: (a) => a.A10 === null ? null : a.A10.split('-')[indice],
            key: "A10"
        },
        {
            title: "A11",
            render: (a) => a.A11 === null ? null : a.A11.split('-')[indice],
            key: "A11"
        },
        {
            title: "A12",
            render: (a) => a.A12 === null ? null : a.A12.split('-')[indice],
            key: "A12"
        },
        {
            title: "A13",
            render: (a) => a.A13 === null ? null : a.A13.split('-')[indice],
            key: "A13"
        },
        {
            title: "A14",
            render: (a) => a.A14 === null ? null : a.A14.split('-')[indice],
            key: "A14"
        },
        {
            title: "A15",
            render: (a) => a.A15 === null ? null : a.A15.split('-')[indice],
            key: "A15"
        },
        {
            title: "A16",
            render: (a) => a.A16 === null ? null : a.A16.split('-')[indice],
            key: "A16"
        },
        {
            title: "A17",
            render: (a) => a.A17 === null ? null : a.A17.split('-')[indice],
            key: "A17"
        },
        {
            title: "A18",
            render: (a) => a.A18 === null ? null : a.A18.split('-')[indice],
            key: "A18"
        },
        {
            title: "A19",
            render: (a) => a.A19 === null ? null : a.A19.split('-')[indice],
            key: "A19"
        },
        {
            title: "A20",
            render: (a) => a.A20 === null ? null : a.A20.split('-')[indice],
            key: "A20"
        },
        {
            title: "A21",
            render: (a) => a.A21 === null ? null : a.A21.split('-')[indice],
            key: "A21"
        },
        {
            title: "A22",
            render: (a) => a.A22 === null ? null : a.A22.split('-')[indice],
            key: "A22"
        },
        {
            title: "A23",
            render: (a) => a.A23 === null ? null : a.A23.split('-')[indice],
            key: "A23"
        },
        {
            title: "A24",
            render: (a) => a.A24 === null ? null : a.A24.split('-')[indice],
            key: "A24"
        },
        {
            title: "A25",
            render: (a) => a.A25 === null ? null : a.A25.split('-')[indice],
            key: "A25"
        },
        {
            title: "A26",
            render: (a) => a.A26 === null ? null : a.A26.split('-')[indice],
            key: "A26"
        },
        {
            title: "A27",
            render: (a) => a.A27 === null ? null : a.A27.split('-')[indice],
            key: "A27"
        },
        {
            title: "A28",
            render: (a) => a.A28 === null ? null : a.A28.split('-')[indice],
            key: "A28"
        },
        {
            title: "A29",
            render: (a) => a.A29 === null ? null : a.A29.split('-')[indice],
            key: "A29"
        },
        {
            title: "A30",
            render: (a) => a.A30 === null ? null : a.A30.split('-')[indice],
            key: "A30"
        },
        {
            title: "A31",
            render: (a) => a.A31 === null ? null : a.A31.split('-')[indice],
            key: "A31"
        },
        {
            title: "A32",
            render: (a) => a.A32 === null ? null : a.A32.split('-')[indice],
            key: "A32"
        },
        {
            title: "A33",
            render: (a) => a.A33 === null ? null : a.A33.split('-')[indice],
            key: "A33"
        },
        {
            title: "A34",
            render: (a) => a.A34 === null ? null : a.A34.split('-')[indice],
            key: "A34"
        },
        {
            title: "A35",
            render: (a) => a.A35 === null ? null : a.A35.split('-')[indice],
            key: "A35"
        },
        {
            title: "A36",
            render: (a) => a.A36 === null ? null : a.A36.split('-')[indice],
            key: "A36"
        },
        {
            title: "A37",
            render: (a) => a.A37 === null ? null : a.A37.split('-')[indice],
            key: "A37"
        },
        {
            title: "A38",
            render: (a) => a.A38 === null ? null : a.A38.split('-')[indice],
            key: "A38"
        },
        {
            title: "A39",
            render: (a) => a.A39 === null ? null : a.A39.split('-')[indice],
            key: "A39"
        },
        {
            title: "A40",
            render: (a) => a.A40 === null ? null : a.A40.split('-')[indice],
            key: "A40"
        },
        {
            title: "A41",
            render: (a) => a.A41 === null ? null : a.A41.split('-')[indice],
            key: "A41"
        },
        {
            title: "A42",
            render: (a) => a.A42 === null ? null : a.A42.split('-')[indice],
            key: "A42"
        },
        {
            title: "A43",
            render: (a) => a.A43 === null ? null : a.A43.split('-')[indice],
            key: "A43"
        },
        {
            title: "A44",
            render: (a) => a.A44 === null ? null : a.A44.split('-')[indice],
            key: "A44"
        },
        {
            title: "A45",
            render: (a) => a.A45 === null ? null : a.A45.split('-')[indice],
            key: "A45"
        },
        {
            title: "A46",
            render: (a) => a.A46 === null ? null : a.A46.split('-')[indice],
            key: "A46"
        },
        {
            title: "A47",
            render: (a) => a.A47 === null ? null : a.A47.split('-')[indice],
            key: "A47"
        },
        {
            title: "A48",
            render: (a) => a.A48 === null ? null : a.A48.split('-')[indice],
            key: "A48"
        },
        {
            title: "A49",
            render: (a) => a.A49 === null ? null : a.A49.split('-')[indice],
            key: "A49"
        },
        {
            title: "A50",
            render: (a) => a.A50 === null ? null : a.A50.split('-')[indice],
            key: "A50"
        },
        {
            title: "A51",
            render: (a) => a.A51 === null ? null : a.A51.split('-')[indice],
            key: "A51"
        },
        {
            title: "A52",
            render: (a) => a.A52 === null ? null : a.A52.split('-')[indice],
            key: "A52"
        },
        {
            title: "A53",
            render: (a) => a.A53 === null ? null : a.A53.split('-')[indice],
            key: "A53"
        },
        {
            title: "A54",
            render: (a) => a.A54 === null ? null : a.A54.split('-')[indice],
            key: "A54"
        },
        {
            title: "A55",
            render: (a) => a.A55 === null ? null : a.A55.split('-')[indice],
            key: "A55"
        },
        {
            title: "A56",
            render: (a) => a.A56 === null ? null : a.A56.split('-')[indice],
            key: "A56"
        },
        {
            title: "A57",
            render: (a) => a.A57 === null ? null : a.A57.split('-')[indice],
            key: "A57"
        },
        {
            title: "A58",
            render: (a) => a.A58 === null ? null : a.A58.split('-')[indice],
            key: "A58"
        },
        {
            title: "A59",
            render: (a) => a.A59 === null ? null : a.A59.split('-')[indice],
            key: "A59"
        },
        {
            title: "A60",
            render: (a) => a.A60 === null ? null : a.A60.split('-')[indice],
            key: "A60"
        },
        {
            title: "A61",
            render: (a) => a.A61 === null ? null : a.A61.split('-')[indice],
            key: "A61"
        },
        {
            title: "A62",
            render: (a) => a.A62 === null ? null : a.A62.split('-')[indice],
            key: "A62"
        },
        {
            title: "A63",
            render: (a) => a.A63 === null ? null : a.A63.split('-')[indice],
            key: "A63"
        },
        {
            title: "A64",
            render: (a) => a.A64 === null ? null : a.A64.split('-')[indice],
            key: "A64"
        },
        {
            title: "A65",
            render: (a) => a.A65 === null ? null : a.A65.split('-')[indice],
            key: "A65"
        },
        {
            title: "A66",
            render: (a) => a.A66 === null ? null : a.A66.split('-')[indice],
            key: "A66"
        },
        {
            title: "A67",
            render: (a) => a.A67 === null ? null : a.A67.split('-')[indice],
            key: "A67"
        },
        {
            title: "A68",
            render: (a) => a.A68 === null ? null : a.A68.split('-')[indice],
            key: "A68"
        },
        {
            title: "A69",
            render: (a) => a.A69 === null ? null : a.A69.split('-')[indice],
            key: "A69"
        },
        {
            title: "A70",
            render: (a) => a.A70 === null ? null : a.A70.split('-')[indice],
            key: "A70"
        },
        {
            title: "A71",
            render: (a) => a.A71 === null ? null : a.A71.split('-')[indice],
            key: "A71"
        },
        {
            title: "A72",
            render: (a) => a.A72 === null ? null : a.A72.split('-')[indice],
            key: "A72"
        },
        {
            title: "A73",
            render: (a) => a.A73 === null ? null : a.A73.split('-')[indice],
            key: "A73"
        },
        {
            title: "A74",
            render: (a) => a.A74 === null ? null : a.A74.split('-')[indice],
            key: "A74"
        },
        {
            title: "A75",
            render: (a) => a.A75 === null ? null : a.A75.split('-')[indice],
            key: "A75"
        },
        {
            title: "A76",
            render: (a) => a.A76 === null ? null : a.A76.split('-')[indice],
            key: "A76"
        },
        {
            title: "A77",
            render: (a) => a.A77 === null ? null : a.A77.split('-')[indice],
            key: "A77"
        },
        {
            title: "A78",
            render: (a) => a.A78 === null ? null : a.A78.split('-')[indice],
            key: "A78"
        },
        {
            title: "A79",
            render: (a) => a.A79 === null ? null : a.A79.split('-')[indice],
            key: "A79"
        },
        {
            title: "A80",
            render: (a) => a.A80 === null ? null : a.A80.split('-')[indice],
            key: "A80"
        },
        {
            title: "A81",
            render: (a) => a.A81 === null ? null : a.A81.split('-')[indice],
            key: "A81"
        },
        {
            title: "A82",
            render: (a) => a.A82 === null ? null : a.A82.split('-')[indice],
            key: "A82"
        },
        {
            title: "A83",
            render: (a) => a.A83 === null ? null : a.A83.split('-')[indice],
            key: "A83"
        },
        {
            title: "A84",
            render: (a) => a.A84 === null ? null : a.A84.split('-')[indice],
            key: "A84"
        },
        {
            title: "A85",
            render: (a) => a.A85 === null ? null : a.A85.split('-')[indice],
            key: "A85"
        },
        {
            title: "A86",
            render: (a) => a.A86 === null ? null : a.A86.split('-')[indice],
            key: "A86"
        },
        {
            title: "A87",
            render: (a) => a.A87 === null ? null : a.A87.split('-')[indice],
            key: "A87"
        },
        {
            title: "A88",
            render: (a) => a.A88 === null ? null : a.A88.split('-')[indice],
            key: "A88"
        },
        {
            title: "A89",
            render: (a) => a.A89 === null ? null : a.A89.split('-')[indice],
            key: "A89"
        },
        {
            title: "A90",
            render: (a) => a.A90 === null ? null : a.A90.split('-')[indice],
            key: "A90"
        },
        {
            title: "A91",
            render: (a) => a.A91 === null ? null : a.A91.split('-')[indice],
            key: "A91"
        },
        {
            title: "A92",
            render: (a) => a.A92 === null ? null : a.A92.split('-')[indice],
            key: "A92"
        },
        {
            title: "A93",
            render: (a) => a.A93 === null ? null : a.A93.split('-')[indice],
            key: "A93"
        },
        {
            title: "A94",
            render: (a) => a.A94 === null ? null : a.A94.split('-')[indice],
            key: "A94"
        },
        {
            title: "A95",
            render: (a) => a.A95 === null ? null : a.A95.split('-')[indice],
            key: "A95"
        },
        {
            title: "A96",
            render: (a) => a.A96 === null ? null : a.A96.split('-')[indice],
            key: "A96"
        },
        {
            title: "A97",
            render: (a) => a.A97 === null ? null : a.A97.split('-')[indice],
            key: "A97"
        },
        {
            title: "A98",
            render: (a) => a.A98 === null ? null : a.A98.split('-')[indice],
            key: "A98"
        },
        {
            title: "A99",
            render: (a) => a.A99 === null ? null : a.A99.split('-')[indice],
            key: "A99"
        },
        {
            title: "A100",
            render: (a) => a.A100 === null ? null : a.A100.split('-')[indice],
            key: "A100"
        },
        {
            title: "A101",
            render: (a) => a.A101 === null ? null : a.A101.split('-')[indice],
            key: "A101"
        },
        {
            title: "A102",
            render: (a) => a.A102 === null ? null : a.A102.split('-')[indice],
            key: "A102"
        },
        {
            title: "A103",
            render: (a) => a.A103 === null ? null : a.A103.split('-')[indice],
            key: "A103"
        },
        {
            title: "A104",
            render: (a) => a.A104 === null ? null : a.A104.split('-')[indice],
            key: "A104"
        },
        {
            title: "A105",
            render: (a) => a.A105 === null ? null : a.A105.split('-')[indice],
            key: "A105"
        },
        {
            title: "servicioclienteA",
            dataIndex: "servicioclienteA",
            key: "servicioclienteA"
        },
        {
            title: "A106",
            render: (a) => a.A106 === null ? null : a.A106.split('-')[indice],
            key: "A106"
        },
        {
            title: "A107",
            render: (a) => a.A107 === null ? null : a.A107.split('-')[indice],
            key: "A107"
        },
        {
            title: "A108",
            render: (a) => a.A108 === null ? null : a.A108.split('-')[indice],
            key: "A108"
        },
        {
            title: "A109",
            render: (a) => a.A109 === null ? null : a.A109.split('-')[indice],
            key: "A109"
        },
        {
            title: "A110",
            render: (a) => a.A110 === null ? null : a.A110.split('-')[indice],
            key: "A110"
        },
        {
            title: "A111",
            render: (a) => a.A111 === null ? null : a.A111.split('-')[indice],
            key: "A111"
        },
        {
            title: "A112",
            render: (a) => a.A112 === null ? null : a.A112.split('-')[indice],
            key: "A112"
        },
        {
            title: "A113",
            render: (a) => a.A113 === null ? null : a.A113.split('-')[indice],
            key: "A113"
        },
        {
            title: "A114",
            render: (a) => a.A114 === null ? null : a.A114.split('-')[indice],
            key: "A114"
        },
        {
            title: "jefetrabajoA",
            dataIndex: "jefetrabajoA",
            key: "jefetrabajoA"
        },
        {
            title: "A115",
            render: (a) => a.A115 === null ? null : a.A115.split('-')[indice],
            key: "A115"
        },
        {
            title: "A116",
            render: (a) => a.A116 === null ? null : a.A116.split('-')[indice],
            key: "A116"
        },
        {
            title: "A117",
            render: (a) => a.A117 === null ? null : a.A117.split('-')[indice],
            key: "A117"
        },
        {
            title: "A118",
            render: (a) => a.A118 === null ? null : a.A118.split('-')[indice],
            key: "A118"
        },
        {
            title: "A119",
            render: (a) => a.A119 === null ? null : a.A119.split('-')[indice],
            key: "A119"
        },
        {
            title: "A120",
            render: (a) => a.A120 === null ? null : a.A120.split('-')[indice],
            key: "A120"
        },
        {
            title: "A121",
            render: (a) => a.A121 === null ? null : a.A121.split('-')[indice],
            key: "A121"
        },
        {
            title: "A122",
            render: (a) => a.A122 === null ? null : a.A122.split('-')[indice],
            key: "A122"
        },
        {
            title: "A123",
            render: (a) => a.A123 === null ? null : a.A123.split('-')[indice],
            key: "A123"
        },
        {
            title: "B1",
            render: (a) => a.B1 === null ? null : a.B1.split('-')[indice],
            key: "B1"
        },
        {
            title: "B2",
            render: (a) => a.B2 === null ? null : a.B2.split('-')[indice],
            key: "B2"
        },
        {
            title: "B3",
            render: (a) => a.B3 === null ? null : a.B3.split('-')[indice],
            key: "B3"
        },
        {
            title: "B4",
            render: (a) => a.B4 === null ? null : a.B4.split('-')[indice],
            key: "B4"
        },
        {
            title: "B5",
            render: (a) => a.B5 === null ? null : a.B5.split('-')[indice],
            key: "B5"
        },
        {
            title: "B6",
            render: (a) => a.B6 === null ? null : a.B6.split('-')[indice],
            key: "B6"
        },
        {
            title: "B7",
            render: (a) => a.B7 === null ? null : a.B7.split('-')[indice],
            key: "B7"
        },
        {
            title: "B8",
            render: (a) => a.B8 === null ? null : a.B8.split('-')[indice],
            key: "B8"
        },
        {
            title: "B9",
            render: (a) => a.B9 === null ? null : a.B9.split('-')[indice],
            key: "B9"
        },
        {
            title: "B10",
            render: (a) => a.B10 === null ? null : a.B10.split('-')[indice],
            key: "B10"
        },
        {
            title: "B11",
            render: (a) => a.B11 === null ? null : a.B11.split('-')[indice],
            key: "B11"
        },
        {
            title: "B12",
            render: (a) => a.B12 === null ? null : a.B12.split('-')[indice],
            key: "B12"
        },
        {
            title: "B13",
            render: (a) => a.B13 === null ? null : a.B13.split('-')[indice],
            key: "B13"
        },
        {
            title: "B14",
            render: (a) => a.B14 === null ? null : a.B14.split('-')[indice],
            key: "B14"
        },
        {
            title: "B15",
            render: (a) => a.B15 === null ? null : a.B15.split('-')[indice],
            key: "B15"
        },
        {
            title: "B16",
            render: (a) => a.B16 === null ? null : a.B16.split('-')[indice],
            key: "B16"
        },
        {
            title: "B17",
            render: (a) => a.B17 === null ? null : a.B17.split('-')[indice],
            key: "B17"
        },
        {
            title: "B18",
            render: (a) => a.B18 === null ? null : a.B18.split('-')[indice],
            key: "B18"
        },
        {
            title: "B19",
            render: (a) => a.B19 === null ? null : a.B19.split('-')[indice],
            key: "B19"
        },
        {
            title: "B20",
            render: (a) => a.B20 === null ? null : a.B20.split('-')[indice],
            key: "B20"
        },
        {
            title: "B21",
            render: (a) => a.B21 === null ? null : a.B21.split('-')[indice],
            key: "B21"
        },
        {
            title: "B22",
            render: (a) => a.B22 === null ? null : a.B22.split('-')[indice],
            key: "B22"
        },
        {
            title: "B23",
            render: (a) => a.B23 === null ? null : a.B23.split('-')[indice],
            key: "B23"
        },
        {
            title: "B24",
            render: (a) => a.B24 === null ? null : a.B24.split('-')[indice],
            key: "B24"
        },
        {
            title: "B25",
            render: (a) => a.B25 === null ? null : a.B25.split('-')[indice],
            key: "B25"
        },
        {
            title: "B26",
            render: (a) => a.B26 === null ? null : a.B26.split('-')[indice],
            key: "B26"
        },
        {
            title: "B27",
            render: (a) => a.B27 === null ? null : a.B27.split('-')[indice],
            key: "B27"
        },
        {
            title: "B28",
            render: (a) => a.B28 === null ? null : a.B28.split('-')[indice],
            key: "B28"
        },
        {
            title: "B29",
            render: (a) => a.B29 === null ? null : a.B29.split('-')[indice],
            key: "B29"
        },
        {
            title: "B30",
            render: (a) => a.B30 === null ? null : a.B30.split('-')[indice],
            key: "B30"
        },
        {
            title: "B31",
            render: (a) => a.B31 === null ? null : a.B31.split('-')[indice],
            key: "B31"
        },
        {
            title: "B32",
            render: (a) => a.B32 === null ? null : a.B32.split('-')[indice],
            key: "B32"
        },
        {
            title: "B33",
            render: (a) => a.B33 === null ? null : a.B33.split('-')[indice],
            key: "B33"
        },
        {
            title: "B34",
            render: (a) => a.B34 === null ? null : a.B34.split('-')[indice],
            key: "B34"
        },
        {
            title: "B35",
            render: (a) => a.B35 === null ? null : a.B35.split('-')[indice],
            key: "B35"
        },
        {
            title: "B36",
            render: (a) => a.B36 === null ? null : a.B36.split('-')[indice],
            key: "B36"
        },
        {
            title: "B37",
            render: (a) => a.B37 === null ? null : a.B37.split('-')[indice],
            key: "B37"
        },
        {
            title: "B38",
            render: (a) => a.B38 === null ? null : a.B38.split('-')[indice],
            key: "B38"
        },
        {
            title: "B39",
            render: (a) => a.B39 === null ? null : a.B39.split('-')[indice],
            key: "B39"
        },
        {
            title: "B40",
            render: (a) => a.B40 === null ? null : a.B40.split('-')[indice],
            key: "B40"
        },
        {
            title: "B41",
            render: (a) => a.B41 === null ? null : a.B41.split('-')[indice],
            key: "B41"
        },
        {
            title: "B42",
            render: (a) => a.B42 === null ? null : a.B42.split('-')[indice],
            key: "B42"
        },
        {
            title: "B43",
            render: (a) => a.B43 === null ? null : a.B43.split('-')[indice],
            key: "B43"
        },
        {
            title: "B44",
            render: (a) => a.B44 === null ? null : a.B44.split('-')[indice],
            key: "B44"
        },
        {
            title: "B45",
            render: (a) => a.B45 === null ? null : a.B45.split('-')[indice],
            key: "B45"
        },
        {
            title: "B46",
            render: (a) => a.B46 === null ? null : a.B46.split('-')[indice],
            key: "B46"
        },
        {
            title: "B47",
            render: (a) => a.B47 === null ? null : a.B47.split('-')[indice],
            key: "B47"
        },
        {
            title: "B48",
            render: (a) => a.B48 === null ? null : a.B48.split('-')[indice],
            key: "B48"
        },
        {
            title: "B49",
            render: (a) => a.B49 === null ? null : a.B49.split('-')[indice],
            key: "B49"
        },
        {
            title: "B50",
            render: (a) => a.B50 === null ? null : a.B50.split('-')[indice],
            key: "B50"
        },
        {
            title: "B51",
            render: (a) => a.B51 === null ? null : a.B51.split('-')[indice],
            key: "B51"
        },
        {
            title: "B52",
            render: (a) => a.B52 === null ? null : a.B52.split('-')[indice],
            key: "B52"
        },
        {
            title: "B53",
            render: (a) => a.B53 === null ? null : a.B53.split('-')[indice],
            key: "B53"
        },
        {
            title: "B54",
            render: (a) => a.B54 === null ? null : a.B54.split('-')[indice],
            key: "B54"
        },
        {
            title: "B55",
            render: (a) => a.B55 === null ? null : a.B55.split('-')[indice],
            key: "B55"
        },
        {
            title: "B56",
            render: (a) => a.B56 === null ? null : a.B56.split('-')[indice],
            key: "B56"
        },
        {
            title: "B57",
            render: (a) => a.B57 === null ? null : a.B57.split('-')[indice],
            key: "B57"
        },
        {
            title: "B58",
            render: (a) => a.B58 === null ? null : a.B58.split('-')[indice],
            key: "B58"
        },
        {
            title: "B59",
            render: (a) => a.B59 === null ? null : a.B59.split('-')[indice],
            key: "B59"
        },
        {
            title: "B60",
            render: (a) => a.B60 === null ? null : a.B60.split('-')[indice],
            key: "B60"
        },
        {
            title: "B61",
            render: (a) => a.B61 === null ? null : a.B61.split('-')[indice],
            key: "B61"
        },
        {
            title: "B62",
            render: (a) => a.B62 === null ? null : a.B62.split('-')[indice],
            key: "B62"
        },
        {
            title: "B63",
            render: (a) => a.B63 === null ? null : a.B63.split('-')[indice],
            key: "B63"
        },
        {
            title: "B64",
            render: (a) => a.B64 === null ? null : a.B64.split('-')[indice],
            key: "B64"
        },
        {
            title: "B65",
            render: (a) => a.B65 === null ? null : a.B65.split('-')[indice],
            key: "B65"
        },
        {
            title: "B66",
            render: (a) => a.B66 === null ? null : a.B66.split('-')[indice],
            key: "B66"
        },
        {
            title: "B67",
            render: (a) => a.B67 === null ? null : a.B67.split('-')[indice],
            key: "B67"
        },
        {
            title: "B68",
            render: (a) => a.B68 === null ? null : a.B68.split('-')[indice],
            key: "B68"
        },
        {
            title: "B69",
            render: (a) => a.B69 === null ? null : a.B69.split('-')[indice],
            key: "B69"
        },
        {
            title: "B70",
            render: (a) => a.B70 === null ? null : a.B70.split('-')[indice],
            key: "B70"
        },
        {
            title: "B71",
            render: (a) => a.B71 === null ? null : a.B71.split('-')[indice],
            key: "B71"
        },
        {
            title: "B72",
            render: (a) => a.B72 === null ? null : a.B72.split('-')[indice],
            key: "B72"
        },
        {
            title: "B73",
            render: (a) => a.B73 === null ? null : a.B73.split('-')[indice],
            key: "B73"
        },
        {
            title: "B74",
            render: (a) => a.B74 === null ? null : a.B74.split('-')[indice],
            key: "B74"
        },
        {
            title: "B75",
            render: (a) => a.B75 === null ? null : a.B75.split('-')[indice],
            key: "B75"
        },
        {
            title: "B76",
            render: (a) => a.B76 === null ? null : a.B76.split('-')[indice],
            key: "B76"
        },
        {
            title: "B77",
            render: (a) => a.B77 === null ? null : a.B77.split('-')[indice],
            key: "B77"
        },
        {
            title: "B78",
            render: (a) => a.B78 === null ? null : a.B78.split('-')[indice],
            key: "B78"
        },
        {
            title: "B79",
            render: (a) => a.B79 === null ? null : a.B79.split('-')[indice],
            key: "B79"
        },
        {
            title: "B80",
            render: (a) => a.B80 === null ? null : a.B80.split('-')[indice],
            key: "B80"
        },
        {
            title: "B81",
            render: (a) => a.B81 === null ? null : a.B81.split('-')[indice],
            key: "B81"
        },
        {
            title: "B82",
            render: (a) => a.B82 === null ? null : a.B82.split('-')[indice],
            key: "B82"
        },
        {
            title: "B83",
            render: (a) => a.B83 === null ? null : a.B83.split('-')[indice],
            key: "B83"
        },
        {
            title: "B84",
            render: (a) => a.B84 === null ? null : a.B84.split('-')[indice],
            key: "B84"
        },
        {
            title: "B85",
            render: (a) => a.B85 === null ? null : a.B85.split('-')[indice],
            key: "B85"
        },
        {
            title: "B86",
            render: (a) => a.B86 === null ? null : a.B86.split('-')[indice],
            key: "B86"
        },
        {
            title: "B87",
            render: (a) => a.B87 === null ? null : a.B87.split('-')[indice],
            key: "B87"
        },
        {
            title: "B88",
            render: (a) => a.B88 === null ? null : a.B88.split('-')[indice],
            key: "B88"
        },
        {
            title: "servicioclienteB",
            dataIndex: "servicioclienteB",
            key: "servicioclienteB"
        },
        {
            title: "B89",
            render: (a) => a.B89 === null ? null : a.B89.split('-')[indice],
            key: "B89"
        },
        {
            title: "B90",
            render: (a) => a.B90 === null ? null : a.B90.split('-')[indice],
            key: "B90"
        },
        {
            title: "B91",
            render: (a) => a.B91 === null ? null : a.B91.split('-')[indice],
            key: "B91"
        },
        {
            title: "B92",
            render: (a) => a.B92 === null ? null : a.B92.split('-')[indice],
            key: "B92"
        },
        {
            title: "B93",
            render: (a) => a.B93 === null ? null : a.B93.split('-')[indice],
            key: "B93"
        },
        {
            title: "B94",
            render: (a) => a.B94 === null ? null : a.B94.split('-')[indice],
            key: "B94"
        },
        {
            title: "B95",
            render: (a) => a.B95 === null ? null : a.B95.split('-')[indice],
            key: "B95"
        },
        {
            title: "B96",
            render: (a) => a.B96 === null ? null : a.B96.split('-')[indice],
            key: "B96"
        },
        {
            title: "B97",
            render: (a) => a.B97 === null ? null : a.B97.split('-')[indice],
            key: "B97"
        },
        {
            title: "EX1",
            render: (a) => a.EX1 === null ? null : a.EX1.split('-')[indice],
            key: "EX1"
        },
        {
            title: "EX2",
            render: (a) => a.EX2 === null ? null : a.EX2.split('-')[indice],
            key: "EX2"
        },
        {
            title: "EX3",
            render: (a) => a.EX3 === null ? null : a.EX3.split('-')[indice],
            key: "EX3"
        },
        {
            title: "EX4",
            render: (a) => a.EX4 === null ? null : a.EX4.split('-')[indice],
            key: "EX4"
        },
        {
            title: "EX5",
            render: (a) => a.EX5 === null ? null : a.EX5.split('-')[indice],
            key: "EX5"
        },
        {
            title: "EX6",
            render: (a) => a.EX6 === null ? null : a.EX6.split('-')[indice],
            key: "EX6"
        },
        {
            title: "EX7",
            render: (a) => a.EX7 === null ? null : a.EX7.split('-')[indice],
            key: "EX7"
        },
        {
            title: "EX8",
            render: (a) => a.EX8 === null ? null : a.EX8.split('-')[indice],
            key: "EX8"
        },
        {
            title: "EX9",
            render: (a) => a.EX9 === null ? null : a.EX9.split('-')[indice],
            key: "EX9"
        },
        {
            title: "EX10",
            render: (a) => a.EX10 === null ? null : a.EX10.split('-')[indice],
            key: "EX10"
        },
        {
            title: "EX11",
            render: (a) => a.EX11 === null ? null : a.EX11.split('-')[indice],
            key: "EX11"
        },
        {
            title: "EX12",
            render: (a) => a.EX12 === null ? null : a.EX12.split('-')[indice],
            key: "EX12"
        },
        {
            title: "EX13",
            render: (a) => a.EX13 === null ? null : a.EX13.split('-')[indice],
            key: "EX13"
        },
        {
            title: "EX14",
            render: (a) => a.EX14 === null ? null : a.EX14.split('-')[indice],
            key: "EX14"
        },
        {
            title: "EX15",
            render: (a) => a.EX15 === null ? null : a.EX15.split('-')[indice],
            key: "EX15"
        },
        {
            title: "EX16",
            render: (a) => a.EX16 === null ? null : a.EX16.split('-')[indice],
            key: "EX16"
        },
        {
            title: "EX17",
            render: (a) => a.EX17 === null ? null : a.EX17.split('-')[indice],
            key: "EX17"
        },
        {
            title: "EX18",
            render: (a) => a.EX18 === null ? null : a.EX18.split('-')[indice],
            key: "EX18"
        },
        {
            title: "EX19",
            render: (a) => a.EX19 === null ? null : a.EX19.split('-')[indice],
            key: "EX19"
        },
        {
            title: "EX20",
            render: (a) => a.EX20 === null ? null : a.EX20.split('-')[indice],
            key: "EX20"
        },
        {
            title: "EX21",
            render: (a) => a.EX21 === null ? null : a.EX21.split('-')[indice],
            key: "EX21"
        },
        {
            title: "EX22",
            render: (a) => a.EX22 === null ? null : a.EX22.split('-')[indice],
            key: "EX22"
        },
        {
            title: "EX23",
            render: (a) => a.EX23 === null ? null : a.EX23.split('-')[indice],
            key: "EX23"
        },
        {
            title: "EX24",
            render: (a) => a.EX24 === null ? null : a.EX24.split('-')[indice],
            key: "EX24"
        },
        {
            title: "EX25",
            render: (a) => a.EX25 === null ? null : a.EX25.split('-')[indice],
            key: "EX25"
        },
        {
            title: "EX26",
            render: (a) => a.EX26 === null ? null : a.EX26.split('-')[indice],
            key: "EX26"
        },
        {
            title: "EX27",
            render: (a) => a.EX27 === null ? null : a.EX27.split('-')[indice],
            key: "EX27"
        },
        {
            title: "EX28",
            render: (a) => a.EX28 === null ? null : a.EX28.split('-')[indice],
            key: "EX28"
        },
        {
            title: "EX29",
            render: (a) => a.EX29 === null ? null : a.EX29.split('-')[indice],
            key: "EX29"
        },
        {
            title: "EX30",
            render: (a) => a.EX30 === null ? null : a.EX30.split('-')[indice],
            key: "EX30"
        },
        {
            title: "EX31",
            render: (a) => a.EX31 === null ? null : a.EX31.split('-')[indice],
            key: "EX31"
        },
        {
            title: "ES1",
            render: (a) => a.ES1 === null ? null : a.ES1.split('-')[indice],
            key: "ES1"
        },
        {
            title: "ES2",
            render: (a) => a.ES2 === null ? null : a.ES2.split('-')[indice],
            key: "ES2"
        },
        {
            title: "ES3",
            render: (a) => a.ES3 === null ? null : a.ES3.split('-')[indice],
            key: "ES3"
        },
        {
            title: "ES4",
            render: (a) => a.ES4 === null ? null : a.ES4.split('-')[indice],
            key: "ES4"
        },
        {
            title: "ES5",
            render: (a) => a.ES5 === null ? null : a.ES5.split('-')[indice],
            key: "ES5"
        },
        {
            title: "ES6",
            render: (a) => a.ES6 === null ? null : a.ES6.split('-')[indice],
            key: "ES6"
        },
        {
            title: "ES7",
            render: (a) => a.ES7 === null ? null : a.ES7.split('-')[indice],
            key: "ES7"
        },
        {
            title: "ES8",
            render: (a) => a.ES8 === null ? null : a.ES8.split('-')[indice],
            key: "ES8"
        },
        {
            title: "ES9",
            render: (a) => a.ES9 === null ? null : a.ES9.split('-')[indice],
            key: "ES9"
        },
        {
            title: "ES10",
            render: (a) => a.ES10 === null ? null : a.ES10.split('-')[indice],
            key: "ES10"
        },
        {
            title: "ES11",
            render: (a) => a.ES11 === null ? null : a.ES11.split('-')[indice],
            key: "ES11"
        },
        {
            title: "ES12",
            render: (a) => a.ES12 === null ? null : a.ES12.split('-')[indice],
            key: "ES12"
        },
        {
            title: "ES13",
            render: (a) => a.ES13 === null ? null : a.ES13.split('-')[indice],
            key: "ES13"
        },
        {
            title: "ES14",
            render: (a) => a.ES14 === null ? null : a.ES14.split('-')[indice],
            key: "ES14"
        },
        {
            title: "ES15",
            render: (a) => a.ES15 === null ? null : a.ES15.split('-')[indice],
            key: "ES15"
        },
        {
            title: "ES16",
            render: (a) => a.ES16 === null ? null : a.ES16.split('-')[indice],
            key: "ES16"
        },
        {
            title: "ES17",
            render: (a) => a.ES17 === null ? null : a.ES17.split('-')[indice],
            key: "ES17"
        },
        {
            title: "ES18",
            render: (a) => a.ES18 === null ? null : a.ES18.split('-')[indice],
            key: "ES18"
        },
        {
            title: "ES19",
            render: (a) => a.ES19 === null ? null : a.ES19.split('-')[indice],
            key: "ES19"
        },
        {
            title: "ES20",
            render: (a) => a.ES20 === null ? null : a.ES20.split('-')[indice],
            key: "ES20"
        },
        {
            title: "ES21",
            render: (a) => a.ES21 === null ? null : a.ES21.split('-')[indice],
            key: "ES21"
        },
        {
            title: "ES22",
            render: (a) => a.ES22 === null ? null : a.ES22.split('-')[indice],
            key: "ES22"
        },
        {
            title: "ES23",
            render: (a) => a.ES23 === null ? null : a.ES23.split('-')[indice],
            key: "ES23"
        },
        {
            title: "ES24",
            render: (a) => a.ES24 === null ? null : a.ES24.split('-')[indice],
            key: "ES24"
        },
        {
            title: "ES25",
            render: (a) => a.ES25 === null ? null : a.ES25.split('-')[indice],
            key: "ES25"
        },
        {
            title: "ES26",
            render: (a) => a.ES26 === null ? null : a.ES26.split('-')[indice],
            key: "ES26"
        },
        {
            title: "ES27",
            render: (a) => a.ES27 === null ? null : a.ES27.split('-')[indice],
            key: "ES27"
        },
        {
            title: "ES28",
            render: (a) => a.ES28 === null ? null : a.ES28.split('-')[indice],
            key: "ES28"
        },
        {
            title: "ES29",
            render: (a) => a.ES29 === null ? null : a.ES29.split('-')[indice],
            key: "ES29"
        },
        {
            title: "ES30",
            render: (a) => a.ES30 === null ? null : a.ES30.split('-')[indice],
            key: "ES30"
        },
        {
            title: "ES31",
            render: (a) => a.ES31 === null ? null : a.ES31.split('-')[indice],
            key: "ES31"
        },
    ]

    const deleteAll = async() => {
        const deleteArrayUser = await deleteData(`${process.env.REACT_APP_API_URL}/api/builder/${idClient}`, state?.token)
        if(deleteArrayUser === 200){
            getAnswers()
        }
    }

    return(
        <div className='admon'>
            <div style={{marginLeft: '2em'}}>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Form.Item
                        label="Cliente"
                        name="cliente"
                        layout="vertical"
                        rules={[
                            {
                            required: true,
                            message: 'Por favor complete este campo',
                            },
                        ]}
                    >
                        <Select defaultValue="Seleccione el cliente" onChange={handleChangeClient}>
                            {clients.map((code, key) => {
                                return(
                                    <Option value={code._id} key={key}>{code.client}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div className='botones'>
                <div><Button onClick={() => setIndice(1)}>Ver x Valores</Button></div>
                <div><Button onClick={() => setIndice(0)}>Ver x Números</Button></div>
                <div><Button type='primary' onClick={() => formatArray()}>Exportar a excel</Button></div>
                <div>
                    <Popconfirm 
                        title="Seguro quiere borrar todo?, esta accion no se puede deshacer" 
                        onConfirm={() => deleteAll()}
                        okText="Si" 
                        cancelText="No"
                    >
                        <Button danger>Eliminar Todo</Button>
                    </Popconfirm>
                </div>
            </div>
            <div className='div-page'>
                <div><h1>Resultados</h1></div>
                    {answers.length > 0 &&
                        <Table dataSource={answers} columns={columns}/>
                    }
            </div>
        </div>
    )
}