import React, {useState, useContext} from 'react';
import {Row, Col, Button, Checkbox, Form, Input} from 'antd'
import {Link, useNavigate} from 'react-router-dom';
import {AppContext} from '../../Provider';
import {loginData} from '../../controller/control'
import './styles.scss'

export default function Login(){
    const [state, setState] = useContext(AppContext)
    const [check, setCheck] = useState(false)

    const navigate = useNavigate()

    const onFinish = async(values) => {
        const log = await loginData(values, `${process.env.REACT_APP_API_URL}/api/user/login`)
        if(log && log.length > 0){
            setState({user: log[0].user, id: log[0]._id, table: log[0].table, token: log[1].token})
            navigate('/home')
        }else{
            alert('Error de usuario o contraseña')
        }
    }

    const onChange = (e) => {
        setCheck(e.target.checked)
    };

    return(
        <div className="login">
            <div className="title">
                <h1>DIAGNOSTICO DE FACTORES DE RIESGO PSICOSOCIAL</h1>
                <p>Resolucion 2646 de 2008</p>
            </div>
            <Row className='description'>
                <Col xs={20} lg={16}>
                    <p>
                        “Doy fe que he recibido la información clara y suficiente por parte de la Organización, sobre la aplicación y objetivos de las Encuestas Psicosociales que la empresa ejecutará como actividad preventiva dentro del marco de la Sistema de Gestión Seguridad y Salud en el Trabajo, por lo tanto me comprometo a suministrar la información completa y veraz requerida en ellas. Así mismo autorizo a la Organización para el manejo de dicha información con fines preventivos y de diseño y ejecución de programas para la prevención y control de los factores de riesgo psicosocial.”
                    </p>
                    <p>
                        Le recomendamos reserve un espacio de tiempo de aproximadamente 40 minutos sin interrupciones para el diligenciamiento del formulario; recuerde que el cuestionario no puede ser diligenciado parcialmente.
                    </p>
                </Col>
            </Row>
            <div className='form'>
                <div className='checkbox'>
                    <Checkbox onChange={onChange}><label style={{color: 'whitesmoke'}}>Acepto los terminos</label></Checkbox>
                </div>
                <div>
                    <Form
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            label={<label style={{color: 'whitesmoke'}}>Usuario</label>}
                            name="user"
                            rules={[
                                {
                                required: true,
                                message: 'Por favor escriba un nombre de usuario!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={<label style={{color: 'whitesmoke'}}>Contraseña</label>}
                            name="pass"
                            rules={[
                                {
                                required: true,
                                message: 'Por favor escriba una contraseña!',
                                },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button ghost htmlType="submit" disabled={!check}>
                                Entrar
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button ghost>
                                <Link to="/loginAdmin">Administrar</Link>
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}