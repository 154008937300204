import {BrowserRouter, Routes, Route} from 'react-router-dom'
import MyProvider from './Provider';
import Home from './Pages/Home'
import LoginAdmin from './Pages/LoginAdmin';
import Login from './Pages/Login';
import Admin from './Pages/Admin';
import './App.css'

function App() {
  return (
    <MyProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}/>
          <Route path="/loginAdmin" element={<LoginAdmin />}/>
          <Route path='/home' element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;
